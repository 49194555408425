<template>
  <div> 
    <div class="row gutter-b">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-header card-header-fixed border-0 px-6">
              <div class="row align-items-center col-8 px-0">
                    <div class="col-4 pr-0">
                      <div class="input-icon">
                        <input 
                          type="text" 
                          class="form-control form-control-solid" 
                          :placeholder="$t('tasks.placeholder.search_title')"
                          v-model="search"
                          >
                        <span>
                          <i class="flaticon2-search-1 text-muted"></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <vs-select v-if="users" v-model="searchUser" :options="users" :placeholder="$t('tasks.placeholder.search_users')" label="full_name" :reduce="users => users.user_id"/>
                    </div>
                    <b-button
                      variant="secondary"
                      class="mr-3 pr-2"
                      @click.prevent="removeFilters"
                    >
                      <i class="fa fa-times icon-md"></i>
                    </b-button>
                    <b-button
                      variant="primary"
                      @click.prevent="searchTasks"
                    >
                    {{ $t("button.filter") }}
                    </b-button>
                </div>
                <div class="card-toolbar">
                  <a href="#" class="btn btn-success" v-b-modal.modal-tasks-edit @click="prepareNewTask()">
                    <span class="svg-icon svg-icon-md svg-icon-white">
                      <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                    </span>
                    {{ $t("tasks.button.create") }}
                  </a>
                </div>
            </div>
          </div>
        </div>
  </div>

  <div class="d-flex flex-row tasks">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-300px tasks-menu">
      <TasksMenu :categories="categories" :tasksMetadata="tasksMetadata"></TasksMenu>
    </div>
    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <div class="card py-2 px-2">

        <div class="d-flex flex-wrap align-items-center px-4 mb-0 border-bottom pl-14">
          <div class="d-flex align-items-center mr-1 my-0">
            <label data-inbox="group-select" class="checkbox checkbox-inline checkbox-primary mr-1">
              <input type="checkbox">
              <span class="symbol-label"></span>
            </label>
            
            <b-dropdown size="sm" variant="clear" no-caret>
              <template #button-content>
                <span class="btn btn-clean btn-icon btn-sm mr-1" data-toggle="dropdown" role="button">
                  <i class="ki ki-bold-arrow-down icon-sm"></i>
                </span>
              </template>
              <b-dropdown-item>{{ $t("tasks.button.complete_tasks") }}</b-dropdown-item>
              <b-dropdown-item>{{ $t("tasks.button.delegate_tasks") }}</b-dropdown-item>
            </b-dropdown>

          </div>
        </div>
        <div class="table-responsive">
            <div class="list list-hover" data-inbox="list">
              <div v-if="!tasks.length" class="text-center mt-15 mb-10 text-muted">{{ $t("tasks.title.no_tasks_found") }}</div>
              <perfect-scrollbar
                class="navi navi-hover scroll my-4 tasks-list"
                style="max-height: 80vh; position: relative;"
                v-else
              >
                <TasksItem 
                v-for="(task, index) in tasks"
                :key="index"
                :task="task"
                ></TasksItem>
              </perfect-scrollbar>
            </div>
        </div>
      </div>
    </div>
    <!--end::Content-->
  </div>

  <!-- Modal for task -->
  <b-modal id="modal-tasks-edit" size="lg" :title="$t('tasks.title.edit_task')" footer-class="justify-content-between">
      <ValidationObserver ref="form_task"> 
              <div class="d-flex">
                  <div class="col-6">
                      <span class="mb-2 d-block">
                          <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                              <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                          </span>
                              {{ $t('tasks.title.task_name') }}
                          </h3>    
                      </span>  
                      <div>
                          <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                          <input 
                                  type="text" 
                                  class="form-control form-control-lg form-control-solid"
                                  :placeholder="$t('tasks.placeholder.task_name')"
                                  v-model="task.name"
                          >                
                          <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                      </div>
                  </div>
                  <div class="col-5 offset-1">
                      <span class="mb-2 d-block">
                          <h3 class="font-size-lg text-primary font-weight-bolder mb-3">
                              <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                      <inline-svg src="/media/svg/icons/Home/Clock.svg" />
                              </span>
                              {{ $t('tasks.title.deadline') }}
                          </h3>
                      </span>  
                      <div class="row">
                          <div class="col-12">
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                  <div class="input-group input-group-lg input-group-solid">
                                    <!-- <date-picker v-model="task.deadline" type="datetime" format="DD.MM.YYYY HH:mm"></date-picker> -->
                                    <date-picker v-model="task.deadline" type="datetime" format="DD.MM.YYYY HH:mm" value-type="YYYY-MM-DD HH:mm"></date-picker>
                                  </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </ValidationProvider>
                          </div>
                      </div>
                  </div>
              </div>

              <b-tabs card class="mt-4" active-nav-item-class="bg-primary text-white">
                  <b-tab :title="$t('tasks.title.tab_main_info')">
                      <b-card-text>
                          <div class="row d-flex align-items-center">
                              <div class="col-6 pr-7">
                                  <strong class="d-block mb-2">{{ $t('tasks.title.main_author') }}</strong>
                                  <vs-select :options="users" v-model="task.from_user" :placeholder="$t('tasks.placeholder.search_users')" class="mb-2" label="full_name"/>
                                  <div class="d-flex justify-content-between">
                                      <strong class="d-inline mt-2 mb-2">{{ $t('tasks.title.main_assigned') }}</strong>
                                      <b-form-checkbox v-model="task.delegate" name="check-button" switch size="l" class="mt-2 d-inline" value=1 unchecked-value=0>{{ $t('tasks.title.delegate') }}</b-form-checkbox>
                                  </div>
                                  <vs-select :options="users" v-model="task.to_user" :placeholder="$t('tasks.placeholder.search_users')" class="mb-2" label="full_name"/>
                                  <strong class="d-block mt-4 mb-2">{{ $t('tasks.title.view_assigned') }}</strong>
                                  <vs-select :options="users" v-model="task.assigment_users" :placeholder="$t('tasks.placeholder.search_users')" label="full_name" multiple/>
                              </div>
                              <div class="col-6 flex-column border-left d-flex pl-7">
                                  <strong class="d-block mb-2">{{ $t('tasks.title.categories') }}</strong>
                                  <vs-select v-model="task.category" :options="categories" :placeholder="$t('tasks.placeholder.categories')" label="name"/>
                                  <hr class="my-7"/>
                                  <div class="d-block priority-list">
                                      <strong class="mr-7">{{ $t('tasks.title.priority') }}</strong>

                                      <b-form-group>
                                        <b-form-radio-group class="pt-4">
                                          <b-form-radio v-model="task.priority" value=3>
                                            <span class="symbol symbol-30 symbol-light-danger mr-6" v-b-tooltip.bottom='$t("tasks.title.priority_high")'>
                                              <span class="label label-sm font-weight-bold label-rounded label-success" style="position: absolute; right: -6px; top: -6px;" v-if="task.priority == 3"><i class="fa fa-check text-white icon-sm"></i></span>
                                                <span class="symbol-label">
                                                    <span class="svg-icon svg-icon-sm svg-icon-danger">
                                                      <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                                    </span>
                                                </span>
                                            </span>
                                          </b-form-radio>

                                          <b-form-radio v-model="task.priority" value=2>
                                            <span class="symbol symbol-30 symbol-light-warning mr-6" v-b-tooltip.bottom='$t("tasks.title.priority_medium")'>
                                                <span class="label label-sm font-weight-bold label-rounded label-success" style="position: absolute; right: -6px; top: -6px;" v-if="task.priority == 2"><i class="fa fa-check text-white icon-sm"></i></span>
                                                <span class="symbol-label">
                                                    <span class="svg-icon svg-icon-sm svg-icon-warning">
                                                      <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                                    </span>
                                                </span>
                                            </span>
                                          </b-form-radio>

                                          <b-form-radio v-model="task.priority" value=1>
                                            <span class="symbol symbol-30 symbol-light-primary mr-6" v-b-tooltip.bottom='$t("tasks.title.priority_normal")'>
                                                <span class="label label-sm font-weight-bold label-rounded label-success" style="position: absolute; right: -6px; top: -6px;" v-if="task.priority == 1"><i class="fa fa-check text-white icon-sm"></i></span>
                                                <span class="symbol-label">
                                                    <span class="svg-icon svg-icon-sm svg-icon-primary">
                                                        <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                                    </span>
                                                </span>
                                            </span>
                                          </b-form-radio>

                                          <b-form-radio v-model="task.priority" value=0>
                                            <span class="symbol symbol-30 mr-6" v-b-tooltip.bottom='$t("tasks.title.priority_low")'>
                                                <span class="label label-sm font-weight-bold label-rounded label-success" style="position: absolute; right: -6px; top: -6px;" v-if="task.priority == 0"><i class="fa fa-check text-white icon-sm"></i></span>
                                                <span class="symbol-label">
                                                    <span class="svg-icon svg-icon-sm">
                                                        <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                                    </span>
                                                </span>
                                            </span>
                                          </b-form-radio>
                                        </b-form-radio-group>
                                      </b-form-group>
                                  </div>
                              </div>
                          </div>
                          <strong class="mt-7 mb-2 d-block">{{ $t('tasks.title.description') }}</strong>
                          <ckeditor :editor="editor" v-model="task.description"></ckeditor>
                      </b-card-text>
                  </b-tab>
                  <b-tab :title="$t('tasks.title.tab_notifications')">
                      <b-card-text>
                          <div class="mt-7 row d-flex">
                              <div class="col-6 pr-7">
                                  <strong class="d-block mb-3">{{ $t('tasks.title.notifications') }}</strong>
                                  <div class="pl-4">
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_7_days_before')"></span></b-form-checkbox>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_3_days_before')"></span></b-form-checkbox>
                                      <div class="separator separator-dashed my-2"></div>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_24_hours_before')"></span></b-form-checkbox>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_12_hours_before')"></span></b-form-checkbox>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_6_hours_before')"></span></b-form-checkbox>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_1_hours_before')"></span></b-form-checkbox>
                                      <div class="separator separator-dashed my-2"></div>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_30_min_before')"></span></b-form-checkbox>
                                      <b-form-checkbox><span v-html="$t('tasks.title.notification_10_min_before')"></span></b-form-checkbox>
                                  </div>

                              </div>
                              <div class="col-6 align-items-center justify-content-center border-left text-center d-flex pl-7">
                                  <span class="text-muted" v-html="$t('tasks.title.period_function_in_progress')"></span>
                              </div>
                          </div>
                      </b-card-text>
                  </b-tab>
              </b-tabs>
      </ValidationObserver>
      <template #modal-footer="{ cancel }" class="d-none">
          <div>
              <b-button variant="danger" v-if="task.task_id" ref="delete_task" @click="deleteTask(task.task_id)">
                  {{ $t('tasks.button.delete_task') }}
              </b-button>   
          </div> 
          <div> 
              <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
              </b-button> 
              <b-button variant="success" @click="submitTask()" ref="task_submit">
                  {{ $t('tasks.button.save_task') }}
              </b-button>     
          </div>
      </template>
  </b-modal>
  
  <b-modal id="modal-tasks-complete" :okTitle="$t('button.complete')" okVariant="success" :title="$t('tasks.title.confirm_complete_task_title')">
      <p v-html="$t('tasks.title.confirm_complete_task_text', {name: this.task.name})"></p>
      <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()" class="mr-3">
            {{ $t('button.cancel') }}
        </b-button> 
        <b-button variant="success"  @click.prevent="completeTask" ref="complete_task">
          {{ $t('tasks.button.complete_task') }}
        </b-button>         
      </template>
  </b-modal>

  <b-modal id="modal-tasks-cat-delete" :okTitle="$t('button.delete')" okVariant="danger" :title="$t('tasks.title.confirm_delete_category_title')">
      <p v-html="$t('tasks.title.confirm_delete_category_text', {category: this.deleteCategory.name})"></p>
      <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()" class="mr-3">
            {{ $t('button.cancel') }}
        </b-button> 
        <b-button variant="danger"  @click.prevent="destroyCategory()" ref="delete_category_submit">
          {{ $t('tasks.button.delete_category') }}
        </b-button>         
      </template>
  </b-modal>

  <b-modal id="modal-tasks-cat-edit" :okTitle="$t('button.save')" okVariant="success" :title="$t('tasks.title.confirm_edit_category_title')">
      <ValidationObserver ref="form_edit_category">
          <ValidationProvider name="name" rules="required|min:3" v-slot="{ errors }">
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('tasks.placeholder.edit_cat_name')"
                  v-model="editCategory.name"
            >                
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()" class="mr-3">
            {{ $t('button.cancel') }}
        </b-button> 
        <b-button variant="success" @click.prevent="updateCategory()" ref="update_category_submit">
          {{ $t('button.save') }}
        </b-button>         
      </template>
  </b-modal>

  <b-modal id="modal-tasks-cat-create" :okTitle="$t('button.save')" okVariant="success" :title="$t('tasks.title.confirm_create_category_title')">
      <ValidationObserver ref="form_new_category">
          <ValidationProvider name="name" rules="required|min:3" v-slot="{ errors }">
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('tasks.placeholder.edit_cat_name')"
                  v-model="newCategory.name"
            >                
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()" class="mr-3">
            {{ $t('button.cancel') }}
        </b-button> 
        <b-button variant="success" @click.prevent="createCategory()" ref="create_category_submit">
          {{ $t('button.create') }}
        </b-button>         
      </template>
  </b-modal>

  <TasksDetailModal :task="task"></TasksDetailModal>
  
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import TasksMenu from "@/view/pages/core/tasks/TasksMenu";
import TasksItem from "@/view/pages/core/tasks/TasksItem";
import TasksDetailModal from "@/view/pages/core/tasks/TasksDetailModal";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';

export default {
  components: {
    TasksMenu,
    TasksItem,
    TasksDetailModal
  },
  data() {
    return {
      loading: false,
      tasks: null,
      tasksMetadata : null,
      endpoint: null,
      categories: null,
      task: {
        delegate: false,
        priority: 0,
      },
      newCategory: {},
      editCategory: {},
      deleteCategory: {},
      users: null,
      editor: ClassicEditor,
      search: "",
      searchType: null,
      searchCategory: null,
      searchUser: null,
      menuActive: null,
      catActive: null
    }
  },
  metaInfo () { 
      return { title: this.$t("tasks.meta.subtitle.list")} 
  },
  beforeMount(){
    this.checkLastSearch();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("tasks.meta.title"), subtitle: this.$t("tasks.meta.subtitle.list") }]);
    this.getTasks();
    this.getCategories();
    this.getUsers();
  },
  methods: {
      getTasks() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet(
        "/tasks?search=" + this.search + "&searchType=" + this.searchType + "&searchCategory=" + this.searchCategory + "&searchUser=" + this.searchUser)
        .then((response) => {
          this.tasks = response.data.data;
          this.tasksMetadata = response.data.metadata;
          this.loading = false;
        });
      },
      submitTask(){
      // Loading spinner
      const createTastButton = this.$refs["task_submit"];
      createTastButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form_task.validate().then(success => {

        
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          createTastButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 

        if(this.task.task_id){
          this.endpoint = "/tasks/" + this.task.task_id + "/update"
        } else {
          this.endpoint  = "/tasks";
        }

        ApiService.apiPost(this.endpoint,
        { 
          'name': this.task.name,
          'deadline': this.task.deadline,
          'delegate': this.task.delegate,
          'from_user': this.task.from_user ? this.task.from_user.user_id : null,
          'to_user': this.task.to_user ? this.task.to_user.user_id : null,
          'category_id': this.task.category ? this.task.category.category_id : null,
          'priority': this.task.priority,
          'description': this.task.description,
          'assigment_users' : this.task.assigment_users
        })
        .then(() => {
            this.loading = false;
            createTastButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$bvModal.hide('modal-tasks-edit');
            this.getTasks();
        }).catch(error => {
            checkErrors(error);
        });
      });
      createTastButton.classList.remove("spinner","spinner-light","spinner-right");
      },

      deleteTask(taskId){
      // Loading spinner
      const deleteTaskButton = this.$refs["delete_task"];
      deleteTaskButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      ApiService.apiPost("/tasks/" + taskId + "/delete")
      .then(() => {
            this.loading = false;
            deleteTaskButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$bvModal.hide('modal-tasks-edit');
            this.getTasks();
      }).catch(error => {
            checkErrors(error);
      });

      deleteTaskButton.classList.remove("spinner","spinner-light","spinner-right");
      },

      completeTask(){
      // Loading spinner
        const completeTaskButton = this.$refs["complete_task"];
        completeTaskButton.classList.add("spinner", "spinner-light", "spinner-right");

        this.loading = true;
        ApiService.apiPost("/tasks/" + this.task.task_id + "/complete")
        .then(() => {
              this.loading = false;
              completeTaskButton.classList.remove("spinner","spinner-light","spinner-right");
              this.$bvModal.hide('modal-tasks-complete');
              this.getTasks();
        }).catch(error => {
              checkErrors(error);
        });

        completeTaskButton.classList.remove("spinner","spinner-light","spinner-right");
      },

      getUsers() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet("/users/all")
        .then((response) => {
          this.users = response.data.data;
          this.loading = false;
        });
      },
      getCategories() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet("/tasks-categories")
        .then((response) => {
          this.categories = response.data.data;
          this.loading = false;
        });
      },
      createCategory(){
      // Loading spinner
      const createCategoryButton = this.$refs["create_category_submit"];
      createCategoryButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form_new_category.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          createCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 
        ApiService.apiPost("/tasks-categories",
        { 
          'name': this.newCategory.name
        })
        .then(() => {
            this.loading = false;
            createCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$bvModal.hide('modal-tasks-cat-create');
            this.getCategories();
        }).catch(error => {
            checkErrors(error);
        });
      });
      createCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
      },
      unsetNewCategory(){
        this.newCategory.name = null;
      },
      updateCategory(){
      // Loading spinner
      const updateCategoryButton = this.$refs["update_category_submit"];
      updateCategoryButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form_edit_category.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          updateCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 
        ApiService.apiPost("/tasks-categories/" + this.editCategory.category_id + "/update",
        { 
          'name': this.editCategory.name
        })
        .then(() => {
            this.loading = false;
            updateCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$bvModal.hide('modal-tasks-cat-edit');
            this.getCategories();
        }).catch(error => {
            checkErrors(error);
        });
      });
      updateCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
      },
      destroyCategory(){
      // Loading spinner
      const deleteCategoryButton = this.$refs["delete_category_submit"];
      deleteCategoryButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      ApiService.apiPost("/tasks-categories/" + this.deleteCategory.category_id + "/delete")
      .then(() => {
            this.loading = false;
            deleteCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$bvModal.hide('modal-tasks-cat-delete');
            this.getCategories();
      }).catch(error => {
            checkErrors(error);
      });

      deleteCategoryButton.classList.remove("spinner","spinner-light","spinner-right");
      },

      setDeleteCategory(category){
        this.deleteCategory = category;
      },
      setEditCategory(category){
        this.editCategory = category;
      },

      setTask(task){
        this.task = {
          task_id: task.task_id,
          name: task.name,
          category: task.category,
          from_user: task.from_user,
          delegate: task.delegate,
          to_user: task.to_user,
          description: task.description,
          priority: task.priority,    
          assigment_users: task.assigment_users,
          deadline: task.deadline,
          deadline_formated: task.deadline_formated, 
          completed: task.completed,
          completed_user: task.completed_user,
          completed_date: task.completed_date
        }
      },
      unsetTask(){
        this.task = {
          delegate: false,
          priority: 0
        }
      },
      prepareNewTask(){
        this.task = {
          name: '',
          priority: 1,
          delegate: 0,
          deadline: moment(new Date()).add(5, 'days').format('YYYY-MM-DD 09:00')
        }
      },
      setCategoryFilter(category){
        if(!category){
          this.searchCategory = null
        } else {
          this.searchCategory = category.category_id;
        }   
        let lastSearch = this.$store.state.search.taskSearch;
        this.taskSearch = {
          search: lastSearch.search,
          searchUser: lastSearch.searchUser,
          searchType: lastSearch.searchType,
          searchCategory: this.searchCategory,
        }
        this.getTasks();
      },
      setTypeFilter(type){
        if(type == "ALL"){
          this.searchType= null 
        } else {
          this.searchType = type;
        }  
        let lastSearch = this.$store.state.search.taskSearch;
        this.taskSearch = {
          search: lastSearch.search,
          searchUser: lastSearch.searchUser,
          searchType: this.searchType,
          searchCategory: lastSearch.searchCategory,
        }
        this.getTasks();  
      },
      checkLastSearch(){
        let lastSearch = this.$store.state.search.taskSearch;
        this.search = lastSearch.search;
        this.searchType = lastSearch.searchType;
        this.searchCategory = lastSearch.searchCategory;
        this.searchUser = lastSearch.searchUser;
      },
      searchTasks(){
        let lastSearch = this.$store.state.search.taskSearch;
        this.getTasks();
        this.taskSearch = {
          search: this.search,
          searchUser: this.searchUser,
          searchType: lastSearch.searchType,
          searchCategory: lastSearch.searchCategory,
        } 
      },
      removeFilters(){       
        this.search = "";
        this.searchUser = null;
        this.searchType = null;
        this.searchCategory = null;
        this.taskSearch = {
          search: this.search,
          searchUser: this.searchUser,
          searchType: this.searchType,
          searchCategory: this.searchCategory,
        }
        this.menuActive = null;
        this.catActive = null;
        this.getTasks(); 
    }
  },
  computed: {
    taskSearch: {
      get () {
        return this.$store.state.search.taskSearch;
      },
      set (value) {
        this.$store.commit('setTaskSearch', value);
      }
    },
  }
};
</script>
