<template>
  <div>
    <div class="d-flex align-items-start list-item py-2 px-1 pr-3 border-bottom tasks-list-item">
      
        <div class="d-flex align-items-center mr-3">
            <div>
                <b-button
                    size="sm"
                    variant="clear"
                    class="pr-1 mr-2 py-0 complete-task"
                    v-b-modal.modal-tasks-complete
                    @click="setTask(task)"
                    v-if="!task.completed"
                    >
                    <i class="far fa-circle icon-l" :class="priorityClass"></i>
                    <i class="far fa-check-circle icon-l" :class=priorityClass></i>
                </b-button>
                <div v-else>
                    <span class="ml-3 mr-5 py-0 d-inline">
                        <i class="far fa-check icon-l text-success"></i>
                    </span>
                </div>
            </div>
            <div class="d-flex align-items-center" v-if="!task.completed">
                <label class="checkbox checkbox-inline checkbox-primary flex-shrink-0 mr-3">
                    <input type="checkbox">
                    <span></span>
                </label>
            </div>
            <div v-else class="px-5">

            </div>
        </div>

        <div class="d-flex flex-column flex-grow-1" v-b-modal.modal-tasks-view @click="setTask(task)">
            <a href="#" class="text-dark-75 font-weight-bold">{{ task.name }}</a>
            <div class="mt-1 d-flex">
                <span class="label label-lg label-light-primary label-inline text-nowrap" v-if="task.category">{{ task.category.name }}</span>
                <div class="text-center container text-muted">
                    <span class="show-tasks-hint">
                        {{ $t('tasks.title.show_task') }}
                    </span>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column justify-content-end mr-5" v-b-modal.modal-tasks-view @click="setTask(task)">
            <div v-if="!task.completed">
                <span v-if="task.from_user">{{ task.from_user.full_name }}</span> 
                <span class="svg-icon svg-icon-sm svg-icon-primary mx-1">
                    <inline-svg src="/media/svg/icons/Navigation/Angle-double-right.svg" />
                </span>
                <strong v-if="task.to_user">{{ task.to_user.full_name }}</strong> 
            </div>
            <div v-else class="text-right">
                <span class="svg-icon svg-icon-sm svg-icon-success mx-1">
                    <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                </span>
                <span v-if="task.completed_user">{{ task.completed_user.full_name }}</span> 
            </div>

            <div class="mt-1 text-right" v-if="!task.completed">
                <span class="text-muted">{{ task.created_at_formated | moment("D. M. YYYY") }}</span>
                <span class="svg-icon svg-icon-sm mx-1">
                    <inline-svg src="/media/svg/icons/Navigation/Angle-double-right.svg" />
                </span>
                <span class="text-success font-weight-bolder">{{ task.deadline_formated | moment("D. M. YYYY") }}</span>
                <Countdown :deadline="task.deadline"></Countdown>
            </div>
            <div v-else class="text-right">
                <span v-if="task.completed_date" class="font-weight-bolder text-success">{{ task.completed_date | moment("D. M. YYYY") }}</span> 
            </div>
        </div>

        <div class="d-flex flex-column justify-content-end" v-b-modal.modal-tasks-edit @click="setTask(task)" v-if="!task.completed">
            <div class="btn btn-icon btn-clean btn-l">
                <span class="svg-icon svg-icon-l">
                    <inline-svg src="/media/svg/icons/Communication/Write.svg" />
                </span>
            </div>
        </div>
        <div class="d-flex flex-column justify-content-end" v-b-modal.modal-tasks-view @click="setTask(task)" v-else>
            <div class="btn btn-icon btn-clean btn-l">
                <span class="svg-icon svg-icon-l">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import Countdown from "@/view/content/Countdown";

export default {
  name: "tasks_item",
  components: {
    Countdown
  },
  props: {
    task: Object
  },
  metaInfo () { 
    
  },
  mounted() {

  },
  computed: {
      priorityClass: function () {
        var priorityClass = '';  
        switch(this.task.priority)
        {
            case 3: priorityClass = "text-danger"; break;
            case 2: priorityClass = "text-warning"; break;
            case 1: priorityClass = "text-primary"; break;
            case 0: priorityClass = ""; break;
        }
        return priorityClass;
      }
  },
  methods: {
    setTask() {
        this.$parent.$parent.setTask(this.task);
    }
  }
};
</script>
