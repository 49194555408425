<template>
    <span v-if="this.diff == null">
        <span class="label label-l label-inline ml-2 text-secondary countdown-label">
            <i class="fas fa-hourglass-half icon-xs"></i>
        </span>
    </span>
    <span v-else>
        <span class="label label-l label-inline ml-2 countdown-label" :class=countClass>
            <span v-if="this.diff == 0">
                {{ $t('title.deadline') }}!
            </span>
            <span v-else-if="days > 0">
                <span>{{ days }}</span>
                <span v-if="days === 1"> {{ $t('title.day') }}</span>
                <span v-else-if="days > 1 && days < 5"> {{ $t('title.days') }}</span>
                <span v-else-if="days => 5"> {{ $t('title.days2') }}</span>
            </span>
            <span v-else>
                <span v-if="hours > 0">
                    <span>{{ hours }}</span> <span>{{ hours > 1 ? 'h' : 'h' }}</span>
                </span>
                <span v-else>
                    <span>{{ minutes | twoDigits }}</span>:<span>{{ seconds | twoDigits }}</span>
                </span>
            </span>
            </span>
    </span>
</template>

<script>
let interval = null;
export default {
    name: 'countdown',
    props: {
        deadline: {
            type: String
        }
    },
    data() {
        return {
            now: Math.trunc((new Date()).getTime() / 1000),
            date: null,
            diff: null
        }
    },
    created() {
        if (!this.deadline) {
            throw new Error("Missing props 'deadline'");
        }
        let endTime = this.deadline;
        this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
        if (!this.date) {
            throw new Error("Invalid props value, correct the 'deadline'");
        }
        interval = setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        }, 1000);

     
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24)
        },
        countClass(){
            // return "label-success";
            if(this.diff == 0)
            {
                return "label-danger";
            }
            else if(this.days >= 1) {
                return "label-success";
            }
            else{
                return "label-warning";
            }
        }
    },
    watch: {
        now() {
            this.diff = this.date - this.now;
            if(this.diff <= 0){
                this.diff = 0;
                // Remove interval
                clearInterval(interval);
            }
        }
    },
    filters: {
        twoDigits(value) {
            if ( value.toString().length <= 1 ) {
                return '0'+value.toString()
            }
            return value.toString()
        }
    },
    destroyed() {
        clearInterval(interval);
    }
}
</script>

<style>
    .countdown-label
    {
        min-width: 42px;
    }
</style>