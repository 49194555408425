<template>
    <div>
        <div class="card card-custom card-stretch gutter-b">
            <!--begin::Body-->
            <div class="card-body px-5 py-4">
                <!--begin:Nav-->
                <div class="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                    <!--begin:Item-->
                    <div class="navi-item my-2">
                        <a href="#" class="navi-link" @click="setTypeFilter('DEADLINE')" v-bind:class="{ 'active' : this.$parent.menuActive == 'DEADLINE'}">
                            <span class="navi-icon mr-4" >
                                <i class="far fa-calendar-times"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg">{{ $t("tasks.title.menu_deadline") }}</span>
                            <span class="navi-label" v-if="tasksMetadata.deadline>0">
                                <span class="label label-rounded label-danger font-weight-bolder">{{tasksMetadata.deadline}}</span>
                            </span>
                        </a>
                    </div>
                    <!--end:Item-->
                    <!--begin:Item-->
                    <div class="navi-item my-2" @click="setTypeFilter('TODAY')">
                        <a href="#" class="navi-link" v-bind:class="{ 'active' : this.$parent.menuActive== 'TODAY'}">
                            <span class="navi-icon mr-4">
                                <span class="svg-icon svg-icon-lg">
                                    <inline-svg src="/media/svg/icons/General/Half-star.svg" />
                                </span>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg">{{ $t("tasks.title.menu_today") }}</span>
                            <span class="navi-label" v-if="tasksMetadata.today>0">
                                <span class="label label-rounded label-info font-weight-bolder">{{tasksMetadata.today}}</span>
                            </span>
                        </a>
                    </div>
                    <!--end:Item-->
                    <!--begin:Item-->
                    <div class="navi-item my-2" @click="setTypeFilter('ALL')">
                        <a href="#" class="navi-link" v-bind:class="{ 'active' : this.$parent.menuActive == 'ALL'}">
                            <span class="navi-icon mr-4">
                                <i class="fas fa-tasks"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg">{{ $t("tasks.title.menu_all_tasks") }}</span>
                        </a>
                    </div>
                    <!--end:Item-->
                    <!--begin:Item-->
                    <div class="navi-item my-2" @click="setTypeFilter('MY_ASSIGNED')">
                        <a href="#" class="navi-link" v-bind:class="{ 'active' : this.$parent.menuActive == 'MY_ASSIGNED'}">
                            <span class="navi-icon mr-4">
                                 <i class="fas fa-user-check"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg">{{ $t("tasks.title.menu_my_assigned") }}</span>
                        </a>
                    </div>
                    <!--end:Item-->
                    <!--begin:Item-->
                    <div class="navi-item my-2" @click="setTypeFilter('COMPLETED')">
                        <a href="#" class="navi-link" v-bind:class="{ 'active' : this.$parent.menuActive == 'COMPLETED'}">
                            <span class="navi-icon mr-4">
                                 <i class="fas fa-check"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg">{{ $t("tasks.title.menu_completed") }}</span>
                        </a>
                    </div>
                    <!--end:Item-->
                    </div>
                </div>
        <!--begin::Body-->
        </div>
        <div class="card card-custom card-stretch tasks-menu-categories">
            <div class="card-body px-5 py-2">
                <!--begin:Nav-->
                <div class="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                    <!--begin:Section-->
                    <div class="navi-section mb-2 font-size-h6 font-weight-bold pb-3">{{ $t("tasks.title.categories") }}</div>
                    <!--end:Section-->

                    <div class="navi-item my-2">
                        <a href="#" class="navi-link">
                            <span class="navi-icon mr-4">
                                <i class="fa flaticon-list-1 text-primary"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg" @click="setCategoryFilter(null)">{{ $t("tasks.button.all_categories") }}</span>
                        </a>
                    </div>

                    <hr />

                    <!--begin:Item-->
                    <div class="navi-item my-2" v-for="(category, index) in categories" :key="index">
                        <a href="#" class="navi-link">
                            <span class="navi-icon mr-4">
                                <i class="fa fa-genderless text-primary"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg" @click="setCategoryFilter(category)">{{ category.name }}</span>
                            <span class="navi-label controls">
                                <a class="btn btn-link btn-xs btn btn-clean btn-hover-light-danger btn-xs btn-icon" @click.prevent="setDeleteCategory(category)" v-b-tooltip.top="$t('tasks.title.delete_category')" v-b-modal.modal-tasks-cat-delete><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                                <a class="btn btn-link btn-xs btn btn-clean btn-hover-light-primary btn-xs btn-icon" @click.prevent="setEditCategory(category)" v-b-tooltip.top="$t('button.settings')" v-b-modal.modal-tasks-cat-edit><i class="ki ki-gear icon-md"></i></a>
                            </span>
                        </a>
                    </div>
                    <!--end:Item-->
                 
                    <hr />
                    <!--begin:Item-->
                    <div class="navi-item my-2">
                        <a href="#" class="navi-link" v-b-modal.modal-tasks-cat-create @click="unsetNewCategory">
                            <span class="navi-icon mr-4">
                                <i class="fa flaticon2-plus icon-1x"></i>
                            </span>
                            <span class="navi-text font-weight-bolder font-size-lg">{{ $t("tasks.button.add_category") }}</span>
                        </a>
                    </div>
                   
                </div>
                <!--end:Nav-->
            </div>
            <!--end::Body-->
        </div>
    </div>
</template>
<script>
export default {
  name: "tasks_menu",
  components: {
  },
  props: {
    categories: null,
    tasksMetadata: null,
  },
  methods: {
    setDeleteCategory(category){
      this.$parent.setDeleteCategory(category);
    },
    setEditCategory(category){
      this.$parent.setEditCategory(category);
    },
    setCategoryFilter(category){
       this.$parent.setCategoryFilter(category); 
    },
    setTypeFilter(type){
       this.$parent.menuActive = type;
       this.$parent.setTypeFilter(type); 
    },
    unsetNewCategory(){
       this.$parent.unsetNewCategory(); 
    }
  }
};
</script>