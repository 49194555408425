<template>
    <div>
        <b-modal id="modal-tasks-view" size="lg" :title="$t('tasks.title.show_task')" >
            <div class="d-flex">
                  <div class="col-1">

                        <span class="symbol symbol-30 symbol-light-danger  mr-6" v-if="task.priority == 3" v-b-tooltip.top='$t("tasks.title.priority_high")'>
                                <span class="symbol-label mt-3">
                                    <span class="svg-icon svg-icon-sm svg-icon-danger">
                                        <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                    </span>
                                </span>
                        </span> 

                        <span class="symbol symbol-30 symbol-light-warning mr-6" v-if="task.priority == 2" v-b-tooltip.top='$t("tasks.title.priority_medium")'>
                                <span class="symbol-label mt-3">
                                    <span class="svg-icon svg-icon-sm svg-icon-warning">
                                        <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                    </span>
                                </span>
                        </span> 

                        <span class="symbol symbol-30 symbol-light-primary mr-6" v-if="task.priority == 1" v-b-tooltip.top='$t("tasks.title.priority_normal")'>
                                <span class="symbol-label mt-3">
                                    <span class="svg-icon svg-icon-sm svg-icon-primary">
                                        <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                    </span>
                                </span>
                        </span> 

                        <span class="symbol symbol-30 mr-6" v-if="task.priority == 0" v-b-tooltip.bottom='$t("tasks.title.priority_low")'>
                            <span class="symbol-label mt-3">
                                <span class="svg-icon svg-icon-sm">
                                    <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
                                </span>
                            </span>
                        </span> 

                  </div>
                  <div class="col-7">
                      <span class="mb-2 d-block">
                          <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                              {{ $t('tasks.title.task_name') }}
                          </h3>    
                      </span>  
                      <div>
                          <h3  class="display-5">
                            {{ task.name }}
                            <br />
                            <span class="label label-lg label-light-primary label-inline text-nowrap mt-3" v-if="task.category">{{ task.category.name }}</span>
                          </h3>
                      </div>
                  </div>
                  <div class="col-4">
                      <span class="mb-2 d-block">
                          <h3 class="font-size-lg text-primary font-weight-bolder mb-3" v-if="!task.completed">
                              <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                      <inline-svg src="/media/svg/icons/Home/Clock.svg" />
                              </span>
                              {{ $t('tasks.title.deadline') }}
                          </h3>
                          <h3 class="font-size-lg text-success font-weight-bolder mb-3" v-else>
                              <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                      <inline-svg src="/media/svg/icons/General/Check.svg" />
                              </span>
                              {{ $t('tasks.title.task_completing') }}
                          </h3>
                      </span>  
                      <div class="row">
                          <div class="col-12">
                              <h5 class="display-6" v-if="!task.completed">
                                {{ task.deadline_formated | moment("D. M. YYYY k:mm") }} <Countdown :deadline="task.deadline"></Countdown>
                              </h5>
                              <h5 class="display-6" v-else>
                                {{ task.completed_date | moment("D. M. YYYY k:mm") }}
                              </h5>
                          </div>
                      </div>
                      <div class="mt-2">
                        <b-button variant="success" class="d-block w-100" v-if="!task.completed" @click="completeTask" ref="complete_task">
                            {{ $t('tasks.button.complete_task') }}
                        </b-button> 
                        <strong v-else>
                            <span class="label label-lg label-success label-inline text-nowrap mt-0"><i class="far fa-check icon-sm mr-2 text-white"></i> {{ $t('tasks.title.task_completed') }}</span>
                        </strong>
                    </div>
                  </div>
              </div>

              <b-tabs card class="" active-nav-item-class="bg-primary text-white">
                  <b-tab :title="$t('tasks.title.tab_main_info')">
                      <b-card-text>
                        <div class=" d-flex text-center mt-3">
                            <div class="flex-column flex-fill pr-7">
                                {{ $t('tasks.title.main_author') }} <br />
                                <h5 class="display-6 mt-1">
                                    {{ task.from_user.full_name}}
                                </h5>
                            </div>
                            <div class="flex-column flex-fill border-left d-flex pl-7">
                                {{ $t('tasks.title.main_assigned') }} <br />
                                <h5 class="display-6 mt-1 font-weight-bolder" :class="!task.completed ? 'text-success' : ''">
                                    {{ task.to_user.full_name}}
                                </h5>
                            </div>
                            <div class="flex-column flex-fill border-left d-flex pl-7" v-if="task.completed">
                                {{ $t('tasks.title.completed_user') }} <br />
                                <h5 class="display-6 mt-1 text-success font-weight-bolder">
                                    {{ task.completed_user.full_name}}
                                </h5>
                            </div>
                        </div>

                        <div v-if="task.description" class="mt-3">
                            <hr />
                            <strong class="d-block mb-2">{{ $t('tasks.title.description') }}</strong>
                            <div v-html=task.description></div>
                        </div>

                      </b-card-text>
                  </b-tab>
                  <b-tab :title="$t('tasks.title.tab_changelog')" @click="getUpdateLog">
                      <b-card-text>
                            <perfect-scrollbar class="navi navi-hover scroll" style="max-height: 300px; position: relative;">
                                <div class="list list-hover">
                                    <div v-for="(log, index) in this.logs" :key="index">
                                        <div class="d-flex align-items-start list-item card-spacer-x py-2">
                                            <!--begin::Info-->
                                            <div class="flex-grow-1 mr-2">
                                                <strong class="text-primary d-inline mr-3" v-b-toggle="'collapse-'+index"><i class="fas fa-search icon-sm mr-1 text-primary"></i> {{ $t('button.detail') }}</strong>
                                                <span class="mr-2">{{ $t('tasks.title.' + Object.keys(log.changes)[0]) }}</span>
                                            </div>
                                            
                                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                                <div><i class="far fa-user icon-sm mr-1"></i> {{ log.user.full_name }}</div>
                                                <div class="font-weight-bolder ml-10"><i class="far fa-calendar-alt icon-sm mr-1"></i> {{ log.created_at_formated }}</div>
                                            </div>
                                        </div>
                                        <b-collapse :id="'collapse-' + index" class="mt-2">
                                            <div class="list-item p-4 mb-2 d-block bg-light">
                                               <strong class="text-danger">{{ $t('tasks.title.old_value') }}: </strong> 
                                               <span v-html="log.changes[Object.keys(log.changes)[0]].old"></span>
                                               <br />
                                               <strong class="text-success">{{ $t('tasks.title.new_value') }}: </strong> 
                                               <span v-html="log.changes[Object.keys(log.changes)[0]].new"></span>
                                            </div>
                                        </b-collapse>
                                    </div>

                                    
                                </div>
                            </perfect-scrollbar>
                      </b-card-text>
                  </b-tab>
             </b-tabs>

            <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">
                    {{ $t('button.close') }}
                </b-button>         
            </template>
        </b-modal>
    </div>
</template>

<script>
import Countdown from "@/view/content/Countdown";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  name: "tasks_item",
  components: {
    Countdown
  },
  data() {
    return {
      logs: null,
    }
  },
  props: {
    task: Object
  },
  methods: {
      completeTask(){
      // Loading spinner
        const completeTaskButton = this.$refs["complete_task"];
        completeTaskButton.classList.add("spinner", "spinner-light", "spinner-right");

        this.loading = true;
        ApiService.apiPost("/tasks/" + this.task.task_id + "/complete")
        .then(() => {
              this.loading = false;
              completeTaskButton.classList.remove("spinner","spinner-light","spinner-right");
              this.$bvModal.hide('modal-tasks-view');
              this.$parent.getTasks();
        }).catch(error => {
              checkErrors(error);
        });

        completeTaskButton.classList.remove("spinner","spinner-light","spinner-right");
      },
      getUpdateLog() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet(
        "/tasks/" + this.task.task_id  + "/update-logs")
        .then((response) => {
          this.logs = response.data.data;
          this.loading = false;
        });
      },
  }
};
</script>
